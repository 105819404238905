import type { FetchError } from 'ofetch';

/**
 * Api error codes: Core API, version 385edb4aae69d74896481693c6958c72bb8a25c4
 */
 
export enum ApiErrorCode {
  Canceled = 1,
  Unknown = 2,
  InvalidArgument = 3,
  DeadlineExceeded = 4,
  NotFound = 5,
  AlreadyExists = 6,
  PermissionDenied = 7,
  Forbidden = 8,
  FailedPrecondition = 9,
  Aborted = 10,
  OutOfRange = 11,
  Unimplemented = 12,
  Internal = 13,
  Unavailable = 14,
  DataLoss = 15,
  Unauthenticated = 16,
  RequestHasForbiddenDeletion = 17,
  RequestHasForbiddenEdition = 18,
  ConcurrentCallAborted = 19,
  MalformedQuery = 20,
  InvalidPhoneFormat = 21,
  MalformedGeometry = 22,
  MetadataRegistryNotComplient = 23,
  UserAlreadyExist = 1001,
  UserMalformedQuery = 1002,
  UserInvalidObjectId = 1003,
  UserNotFound = 1004,
  UserUnknownRole = 1005,
  UserUnknownCustomer = 1006,
  UserCustomerAlreadyExists = 1007,
  UserRoleAlreadyExists = 1008,
  UserCannotDeleteUsedPaymentMethod = 1009,
  UserCannotCreatePaymentMethod = 1010,
  UserUnknownCoupon = 1011,
  UserCouponAlreadyUsed = 1012,
  UserCouponExpired = 1013,
  UserCouponInvalid = 1014,
  UserEmailAlreadyAdded = 1015,
  UserCannotRemovePrimaryEmail = 1016,
  UserSubscriptionAlreadyActive = 1017,
  UserNoteNotFound = 1018,
  UserNewUserRequired = 1019,
  UserInactiveUserRequired = 1020,
  UserHasMoreThanOnceStripeKey = 1021,
  UserDefaultScopeAttachedToPaymentProvider = 1022,
  UserHasNoOtherScope = 1023,
  UserCannotGiveRole = 1024,
  UserScopeIdsMustBeSpecified = 1025,
  UserRfidInformationMustBeProvided = 1026,
  UserRfidProductNotFound = 1027,
  UserRfidDecoderNotImplemented = 1028,
  UserRfidTagCantBeDecoded = 1029,
  UserRfidSessionNotFound = 1030,
  UserRfidUidNotFound = 1031,
  UserRfidSessionAlreadyExists = 1032,
  UserRfidTagAlreadyExists = 1033,
  UserIncoherentStatus = 1034,
  UserHasContract = 1035,
  UserHasBillableBenefits = 1036,
  UserBalanceNotNull = 1037,
  UserHasBillableRentals = 1038,
  UserIsSuspended = 1039,
  UserIsAnonymized = 1040,
  UserRfidTagLimitExceeded = 1041,
  UserApiKeyConflict = 1042,
  UserApiKeyNotFound = 1043,
  UserRoleShouldBeInSnakeCase = 1044,
  UserMissingBirthdate = 1045,
  UserTooYoung = 1046,
  UserRfidDecodingImpossible = 1047,
  UserRfidManualEntryAddingDisabled = 1048,
  UserRfidBikeScanAddingDisabled = 1049,
  UserPasswordNeedsMinimalLength = 1050,
  UserPasswordNeedsNumericValue = 1051,
  UserPasswordNeedsUpperCaseValue = 1052,
  UserPasswordNeedsLowerCaseValue = 1053,
  UserPasswordNeedsSpecialCharacter = 1054,
  UserInvalidPincode = 1055,
  BikeActionIdAlreadyUsed = 2001,
  BikeActionTypeAlreadyUsed = 2002,
  BikeSerialNumberAlreadyUsed = 2003,
  BikeIssueIdAlreadyUsed = 2004,
  BikeMalformedQuery = 2005,
  BikeInvalidSerialNumber = 2006,
  BikeActionNotFound = 2007,
  BikeActionTypeNotFound = 2008,
  BikeNotFound = 2009,
  BikeIssueNotFound = 2010,
  BikeRoleUnknown = 2011,
  BikeNotificationAlreadyExists = 2012,
  BikeNotificationNotFound = 2013,
  BikeStationNotFound = 2014,
  BikeUnknownArea = 2015,
  BikeInvalidBikeLocation = 2016,
  BikeWrongMaintenanceWorkflow = 2017,
  BikeDataNotFound = 2018,
  BikeDataAlreadyRegistered = 2019,
  BikeIsNotOutOfOrderOrInLowBatteryState = 2020,
  BikeCannotBeInfieldMaintenanceState = 2021,
  BikeCannotCreateIssue = 2022,
  BikeSimMustBeActivatedForBikeToExitWarehouse = 2023,
  BikeForbidden = 2024,
  BikeWrongLostStatusWorkflow = 2025,
  BikeActionMustHaveOneFlag = 2026,
  BikeActionMustUpdateSomething = 2027,
  BikeUnavailableBike = 2028,
  BikeOutOfOrder = 2029,
  BikeStationedBikeOutOfOrder = 2030,
  BikeLowBattery = 2031,
  BikeStationedBikeLowBattery = 2032,
  BikeCriticalBattery = 2033,
  BikeInUse = 2034,
  BikeFirmwareMissing = 2035,
  BikeUnreachable = 2036,
  BikeNotConnected = 2037,
  BikeIccidUnknown = 2038,
  BikeLocationOutdated = 2039,
  BikeLocationTimestampMissing = 2040,
  BikeLocationMissing = 2041,
  BikeUntrackedLwm2mObjectId = 2042,
  BikeLocationInvalidGeo = 2043,
  BikeUnimplementedLwm2mObjectId = 2044,
  BikeSoftUnlockNotSupported = 2045,
  BikeStationedMaintenanceBikeOutOfOrder = 2046,
  BikeStationedMaintenanceBikeLowBattery = 2047,
  BikeNotLastOfStack = 2048,
  BikeLastChangeTooOld = 2049,
  BikeUnimplementedMethod = 2050,
  BikeSentAtTimestampTooOld = 2051,
  BikeLastChangeInFuture = 2052,
  BikeStateAlreadyReceived = 2053,
  AuthUserAlreadyExists = 3001,
  AuthInvalidCode = 3002,
  AuthInvalidToken = 3003,
  AuthInvalidRefreshToken = 3004,
  AuthInvalidPassword = 3005,
  AuthUserNotFound = 3006,
  AuthInvalidPhoneFormat = 3007,
  AuthBasicAuthNotSupportedForUser = 3008,
  AuthUnknownApiKey = 3009,
  AuthInvalidApiKey = 3010,
  AuthApiKeyNotActivated = 3011,
  AuthMalformedApiKey = 3012,
  AuthMalformedQuery = 3013,
  AuthScopeNotFound = 3014,
  AuthScopeAlreadyExists = 3015,
  AuthClientNotFound = 3016,
  AuthClientAlreadyExists = 3017,
  AuthClientIdIsMissing = 3018,
  AuthInvalidEmailFormat = 3022,
  AuthRoleBindingNotFound = 3023,
  AuthRoleBindingAlreadyExists = 3024,
  AuthSelectorNotFound = 3025,
  AuthSelectorAlreadyExists = 3026,
  AuthMetaPermissionAlreadyExists = 3027,
  AuthRoutesPermissionAlreadyExists = 3028,
  AuthFieldsPermissionAlreadyExists = 3029,
  AuthInvalidOtpLength = 3030,
  AuthRegistrationForbiddenOnScope = 3031,
  RentalInvalidObjectId = 4001,
  RentalMalformedQuery = 4002,
  RentalConflict = 4003,
  RentalTripNotFound = 4004,
  RentalUserCantStartTrip = 4005,
  RentalInvalidTripStatus = 4006,
  RentalUnavailableVehicle = 4007,
  RentalWrongVehicle = 4008,
  RentalNoVehicleInRange = 4009,
  RentalNeedRerouteCriticalArea = 4010,
  RentalNeedRerouteStationFull = 4011,
  RentalNeedRerouteForbiddenArea = 4012,
  RentalNoPathAvailable = 4013,
  RentalDepositStationFull = 4014,
  RentalSamePickupAndDeposit = 4015,
  RentalUnknownStation = 4017,
  RentalInvalidArrival = 4019,
  RentalVehicleHasNoLocation = 4020,
  RentalVehicleNearStation = 4021,
  RentalPickupTooFar = 4022,
  RentalArrivalOutsideServiceableArea = 4023,
  RentalArrivalInsideForbiddenArea = 4024,
  RentalInvalidUserStatus = 4025,
  RentalInvalidBookingStatus = 4026,
  RentalForbidden = 4027,
  RentalPaymentMethodNotGiven = 4028,
  RentalVehicleReserved = 4033,
  RentalUnknownPricing = 4034,
  RentalIncoherentPricingParameters = 4035,
  RentalIncoherentPricingRule = 4036,
  RentalPaymentNeedConfirmation = 4037,
  RentalCannotRefundUnbilledTrip = 4038,
  RentalEnvironmentMismatch = 4039,
  RentalCannotRefundWithAmountMismatch = 4040,
  RentalStationIsNotAvailable = 4041,
  RentalVehicleHasInsufficientBattery = 4042,
  RentalRevokeLeasingIsNeeded = 4043,
  RentalEstimationNotFound = 4044,
  RentalBookingNotFound = 4045,
  RentalVehiclestateTimestampInvalid = 4046,
  RentalMissingRole = 4047,
  RentalWrongArea = 4048,
  RentalImpossibleToCapturePreauth = 4049,
  RentalEndVerifyOnStackedVehicle = 4050,
  RentalVehicleStackedOnSharingTrip = 4051,
  RentalTripCooldownNotEnded = 4052,
  RentalNotFound = 4053,
  RentalConflictWithExistingRental = 4054,
  RentalUserAndAreaHaveNoPricing = 4055,
  RentalHasActiveTrip = 4056,
  RentalVehicleIsRented = 4057,
  RentalVehicleIsInTrip = 4058,
  RentalTripInvalidMaintenanceState = 4059,
  RentalMetadataNotCompliant = 4060,
  RentalCantSetMaintenanceStateWithoutSoftUnlock = 4061,
  RentalTripHasUnlockVehicleState = 4062,
  RentalBookingMaxedOutOnStation = 4063,
  RentalHasRentedVehicles = 4064,
  RentalStartNotAllowedOnArea = 4065,
  RentalTooMuchVehiclesRented = 4066,
  RentalNotActive = 4067,
  RentalHasIncoherentCurrency = 4068,
  RentalPaymentBelowMinimum = 4069,
  RentalBookingConflict = 4070,
  RentalEstimationConflict = 4071,
  RentalNoRouteFound = 4072,
  RentalVehicleStateTimestampTooOld = 4073,
  RentalStartImpossibleWithOutdatedVehicleState = 4074,
  RentalNeedsPayment = 4075,
  RentalSupportIsOpen = 4076,
  RentalVehicleCannotEmergencyEnd = 4077,
  StationAlreadyExists = 5001,
  StationMalformedQuery = 5002,
  StationInvalidObjectId = 5003,
  StationNotFound = 5004,
  StationIsFull = 5005,
  StationNoBikeAvailable = 5006,
  StationNotEnoughBikesAfterTheft = 5007,
  StationRoleUnknown = 5008,
  StationOutsideArea = 5009,
  StationFastenerOutsideCriticalArea = 5010,
  StationFastenerMissingLabel = 5011,
  StationFastenerMissingLocation = 5012,
  StationFastenerVirtualAreaOutsideCriticalArea = 5013,
  StationUrbanFurnitureNotFound = 5014,
  StationFastenerMissingStationId = 5015,
  StationFastenerStationIdCantBeUpdated = 5016,
  StationFastenerInUse = 5017,
  StationFastenerConflict = 5018,
  StationFastenerNotFound = 5019,
  StationHasFasteners = 5020,
  StationFastenerDockWithSerialNumber = 5021,
  StationFastenerStackWithRfidUid = 5022,
  StationFastenerAllowedInOrderStatusCantBeEmpty = 5023,
  StationFastenerVirtualOverlapping = 5024,
  AreaAlreadyExist = 6001,
  AreaMalformedQuery = 6002,
  AreaMalformedCoordinates = 6003,
  AreaNotFound = 6004,
  AreaRoleUnknown = 6005,
  AreaInvalidArea = 6006,
  AreaWithDuplicatedForbidden = 6007,
  AreaUnknowCountryCode = 6008,
  AreaStationOutsideArea = 6009,
  AreaMalformedGeometry = 6010,
  WebhookInvalidObjectId = 7001,
  WebhookMalformedQuery = 7002,
  WebhookConflict = 7003,
  WebhookNotFound = 7004,
  WebhookHmacVerificationFailed = 7005,
  WebhookRemoteServerError = 7006,
  WebhookScopeNotAllowed = 7007,
  WebhookLocked = 7008,
  WebhookInvalidPublisher = 7009,
  PaymentUserHasMoreThanOneScopeWithPaymentProvider = 13001,
  PaymentNoPaymentProviderFoundWithUserScopes = 13002,
  PaymentScopeProviderNameUnknown = 13003,
  PaymentProviderAccountNotFound = 13004,
  PaymentConflictWithExsitingProviderAccount = 13005,
  PaymentMethodNeeded = 13006,
  PaymentNotFound = 13008,
  PaymentConflictWithExsitingPayment = 13009,
  PaymentPricingNotFound = 13011,
  PaymentConflictWithExsitingPricing = 13012,
  PaymentMalformedQuery = 13013,
  PaymentIncoherentPricingParameters = 13014,
  PaymentIncoherentPricingRule = 13015,
  PaymentDiscountNotFound = 13016,
  PaymentConflictWithExsitingDiscount = 13017,
  PaymentUnknownCoupon = 13018,
  PaymentCouponAlreadyUsed = 13019,
  PaymentCouponExpired = 13020,
  PaymentCouponInvalid = 13021,
  PaymentErrorFromPaymentProvider = 13022,
  PaymentWebhookEventMissingParams = 13023,
  PaymentWebhookTypeNotSet = 13024,
  PaymentWebhookUnimplementedType = 13025,
  PaymentCustomerNotFound = 13026,
  PaymentConflictWithExsitingCustomer = 13027,
  PaymentProviderInactive = 13028,
  PaymentProviderUnimplemented = 13029,
  PaymentWebhookEventMissingInvoiceUrl = 13030,
  PaymentSubscriptionNotFound = 13031,
  PaymentConflictWithExsitingSubscription = 13032,
  PaymentMultiplePricingReturned = 13033,
  PaymentMethodNotImplementedForThisPaymentProvider = 13034,
  PaymentCannotRefundPaymentsWithDifferentCustomer = 13035,
  PaymentOfferConflict = 13036,
  PaymentOfferNotFound = 13037,
  PaymentBenefitConflict = 13038,
  PaymentBenefitNotFound = 13039,
  PaymentCannotResumeBenefit = 13040,
  PaymentWrongBenefitType = 13041,
  PaymentWrongBenefitStatus = 13042,
  PaymentOfferPolicyNotFound = 13043,
  PaymentOfferPolicyConflict = 13044,
  PaymentOfferNotAvailable = 13045,
  PaymentMetadataNotComplient = 13046,
  PaymentCustomerPaymentMethodMandatoryBeforeUpdate = 13047,
  PaymentCardTokenInvalidOrReused = 13048,
  PaymentChallengeNoLongerActive = 13049,
  PaymentChallengeFailed = 13050,
  PaymentChallengeErrored = 13051,
  PaymentTransactionInvalid = 13052,
  PaymentCaptureAboveAuthorization = 13053,
  PaymentRefundAboveCapture = 13054,
  PaymentMethodDuplicate = 13055,
  PaymentSubscriptionEnded = 13056,
  PaymentInvalidPlan = 13057,
  PaymentCurrencyNotSupportedByProvider = 13058,
  PaymentInvalidPaymentProvided = 13059,
  PaymentRestrictedOffersForbidden = 13060,
  PaymentBenefitTargetNotAllowed = 13061,
  PaymentOfferWithoutAreaIds = 13062,
  PaymentBenefitNotEnded = 13063,
  PaymentCannotPauseBenefit = 13064,
  PaymentIncoherentCurrency = 13065,
  PaymentTransactionNotFound = 13066,
  PaymentTransactionConflict = 13067,
  PaymentOfferPolicyInvalid = 13068,
  PaymentBelowMinimum = 13069,
  PaymentMethodTypeNotAllowed = 13070,
  PaymentInvalidDuracron = 13071,
  NotifierMoreThanOneClientIdWithFirebaseApiKey = 22001,
  NotifierNoClientMessagingsFound = 22002,
  NotifierNoMailApikeyProvided = 22003,
  NotifierNoFirebaseInfoForClientUpdate = 22004,
  NotifierMalformedQuery = 22005,
  NotifierScopeIdNotProvided = 22006,
  NotifierUserSpam = 22007,
  NotifierCountryCodeSpam = 22008,
  NotifierGlobalSpam = 22009,
  NotifierIsSpam = 22010,
  NotifierTokenCaptchaIsMissing = 22011,
  NotifierCaptchaFailed = 22012,
  NotifierHcaptchaError = 22013,
  NotifierCountryCodeBlocked = 22014,
  NotifierSuspiciousCountryCodeSpam = 22015,
  NotifierSuspiciousOriginCountryCodeSpam = 22016,
  IssueMalformedQuery = 23001,
  IssueMetadataNotComplient = 23002,
  IssueNotFound = 23003,
  TicketIssueConflict = 23004,
  TicketIssueLabelNotFound = 23005,
  TicketIssueLabelConflict = 23006,
  FleetMalformedQuery = 24001,
  FleetProductNotFound = 24002,
  FleetDeployGroupNotFound = 24003,
  FleetProductVersionNotFound = 24004,
  FleetBuildNotFound = 24005,
  FleetEnvironmentNotFound = 24006,
  FleetFirmwareNotFound = 24007,
  FleetWrongFirmwareFileGiven = 24008,
  FleetBikeSimUnknown = 24009,
  FleetInvalidApiKey = 24010,
  FleetInvalidToken = 24011,
  FleetNoTokenNorApikeyProvided = 24012,
  FleetAlreadyAttached = 24013,
  FleetNoGatewayForProductType = 24014
}
export type ApiErrorData = {
  code: ApiErrorCode;
  message: string;
};

export interface ApiError extends FetchError<ApiErrorData> {
  meta?: {
    traceId?: string;
  };
}
      